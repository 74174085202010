import { VscArrowLeft } from 'react-icons/vsc';
import { Button, type ButtonProps } from '@/ui';

export const BackButton = (props: ButtonProps) => {
  return (
    <Button
      _hover={{ textDecor: 'underline' }}
      fontWeight="medium"
      variant="unstyled"
      leftIcon={
        <VscArrowLeft
          style={{ position: 'relative', top: 2, marginRight: 4 }}
        />
      }
      {...props}
    />
  );
};
