import { useMemo } from 'react';
import useEvent from 'react-use-event-hook';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import {
  createDecoratedEntry,
  getEntryInstances,
  groupInstancesByDay,
  groupInstancesByMonth,
  sortEntryInstances,
} from '@/pages/Schedule/utils';
import { Box, Flex, Heading } from '@/ui';
import { useSnippet } from '../../hooks/useSnippet';
import { EmptyState } from './components/EmptyState';
import { EntryTable } from './components/EntryTable';
import { MobileEntryItem } from './components/MobileEntryItem';

type Props = {
  showHiddenEntries?: boolean;
  onEntryClick: (entry: DecoratedEntry, instance: DecoratedInstance) => void;
  onToggleHideEntry?: (
    entry: DecoratedEntry,
    instance: DecoratedInstance
  ) => void;
};

export const SnippetEntries = ({
  showHiddenEntries,
  onEntryClick,
  onToggleHideEntry,
}: Props) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { data: snippet, startDate, endDate } = useSnippet();

  const scheduleTimeZone = snippet?.scheduleTimeZone ?? 'UTC';

  const { entries, groupedInstances } = useMemo(() => {
    const entries = (snippet?.entries ?? []).map((entry) => {
      return createDecoratedEntry(entry, scheduleTimeZone);
    });

    const instances = entries.reduce<DecoratedInstance[]>(
      (instances, entry) => {
        const expandedInstances = getEntryInstances(
          entry,
          startDate,
          endDate,
          scheduleTimeZone
        );
        return instances.concat(expandedInstances);
      },
      []
    );

    const visibleInstances = showHiddenEntries
      ? instances
      : (instances.filter(({ isHidden }) => !isHidden) ?? []);

    const sortedInstances = sortEntryInstances(visibleInstances);

    const groupedInstances = isMobileBreakpoint
      ? groupInstancesByDay(sortedInstances)
      : groupInstancesByMonth(sortedInstances);

    return { entries, groupedInstances };
  }, [
    snippet?.entries,
    showHiddenEntries,
    isMobileBreakpoint,
    startDate,
    endDate,
    scheduleTimeZone,
  ]);

  const handleEntryClick = useEvent((instance: DecoratedInstance) => {
    const entry = entries.find((entry) => entry.id === instance.parentId);
    if (entry) {
      onEntryClick(entry, instance);
    }
  });

  const handleToggleHideEntryClick = useEvent((instance) => {
    const entry = entries.find((entry) => entry.id === instance?.parentId);
    if (entry) {
      onToggleHideEntry?.(entry, instance);
    }
  });

  if (!entries?.length) {
    return <EmptyState />;
  }

  return (
    <Flex direction="column">
      {groupedInstances.map(({ dateGroup, instances }) => (
        <Box _notFirst={{ mt: 12 }} key={dateGroup}>
          <Heading
            borderBottomWidth="3px"
            borderColor="customgray.dark"
            fontSize={{ base: '22px', md: '34px' }}
            fontWeight="bold"
            mb="2"
            pb={{ base: 2, md: 4 }}
          >
            {dateGroup}
          </Heading>

          {isMobileBreakpoint ? (
            instances.map((instance, entryIndex) => (
              <MobileEntryItem
                instance={instance}
                key={instance.id}
                mt={entryIndex === 0 ? 3 : 2}
                onClick={handleEntryClick}
                onToggleHideEntryClick={
                  onToggleHideEntry ? handleToggleHideEntryClick : undefined
                }
              />
            ))
          ) : (
            <EntryTable
              instances={instances}
              onEntryClick={handleEntryClick}
              onToggleHideEntryClick={
                onToggleHideEntry ? handleToggleHideEntryClick : undefined
              }
            />
          )}
        </Box>
      ))}
    </Flex>
  );
};
