import type { ReactNode } from 'react';
import { Text } from '@/ui';

type Props = {
  children: ReactNode;
};

export const ColumnHeader = ({ children }: Props) => {
  return (
    <Text
      borderBottomColor="customgray.dark"
      borderBottomWidth="2px"
      fontWeight="medium"
      mb="4"
      pb="1"
    >
      {children}
    </Text>
  );
};
