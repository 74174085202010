import { Flex } from '@/ui';

export const AccessDenied = () => {
  return (
    <Flex
      color="customgray.mid"
      flex="1"
      fontSize={{ base: 'lg', md: '2xl' }}
      justify="center"
      pt="10"
      textAlign="center"
    >
      Sorry, you don&apos;t have access to view this snippet
    </Flex>
  );
};
