import useEvent from 'react-use-event-hook';
import { usePageTitle } from '@/hooks/usePageTitle';
import { useTrackPageView } from '@/hooks/useTrackPageView';
import {
  EntryModalContextProvider,
  ReadOnlyEntryModal,
} from '@/pages/Schedule/components';
import { useDraftEntryStore } from '@/pages/Schedule/stores';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import { Flex, Heading } from '@/ui';
import { Formula } from './components/Formula';
import { SnippetEntries } from './components/SnippetEntries';
import { useSnippet } from './hooks/useSnippet';

export const UnauthenticatedScheduleSnippet = () => {
  useTrackPageView('schedule:snippet', { public: true });
  const { data: snippet } = useSnippet();
  const editDraftEntry = useDraftEntryStore((state) => state.editDraftEntry);
  const clearDraftEntry = useDraftEntryStore((state) => state.clearDraftEntry);

  usePageTitle(snippet?.name);

  const handleEntryClick = useEvent(
    (entry: DecoratedEntry, instance: DecoratedInstance) => {
      editDraftEntry(entry, instance);
    }
  );

  return (
    <>
      <EntryModalContextProvider>
        <ReadOnlyEntryModal onClose={() => clearDraftEntry()} />
      </EntryModalContextProvider>

      <Flex direction="column" flex="1" px={{ base: 0, xl: 100 }}>
        <Heading
          as="h1"
          fontSize={{ base: '32px', md: '60px' }}
          fontWeight="medium"
          textAlign="center"
        >
          {snippet?.name}
        </Heading>

        <Formula mb={{ base: 8, md: 16 }} mt="5" />

        <SnippetEntries
          showHiddenEntries={false}
          onEntryClick={handleEntryClick}
        />
      </Flex>
    </>
  );
};
