import { useCallback } from 'react';
import { useUpdateEntry } from '@/pages/Schedule/hooks';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';

export const useToggleHide = () => {
  const { hideEntry, unhideEntry } = useUpdateEntry();

  const toggleHide = useCallback(
    (entry: DecoratedEntry, instance: DecoratedInstance) => {
      if (instance.isHidden) {
        unhideEntry(entry, instance);
      } else {
        hideEntry(entry, instance);
      }
    },
    [hideEntry, unhideEntry]
  );

  return { toggleHide };
};
