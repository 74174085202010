import type { DecoratedInstance } from '@/pages/Schedule/types';
import { Flex } from '@/ui';
import { EntryTableRow } from './EntryTableRow';

type Props = {
  instances: DecoratedInstance[];
  onEntryClick: (instance: DecoratedInstance) => void;
  onToggleHideEntryClick?: (instance: DecoratedInstance) => void;
};

export const EntryTable = ({
  instances,
  onEntryClick,
  onToggleHideEntryClick,
}: Props) => {
  return (
    <Flex
      direction="column"
      gap="2"
      sx={{
        '.header': {
          borderBottomWidth: 1,
          borderBottomColor: 'customgray.gray',
          div: {
            fontWeight: 'bold',
          },
        },
        '.row': {
          flex: 1,
          width: '100%',
          borderRadius: 8,
        },
        '.cell': {
          alignItems: 'center',
          px: 5,
          py: 3,
          '@media print': {
            fontSize: 12,
          },
        },
        '.date': {
          width: { base: '115px', xl: '165px' },
          whiteSpace: 'nowrap',
          fontWeight: 'medium',
        },
        '.emoji': {
          width: '30px',
        },
        '.title': {
          flex: 1,
          paddingLeft: 3,
          fontWeight: 'medium',
        },
        '.time': {
          width: { base: '120px', lg: '200px', '2xl': '225px' },
          '@media print': {
            width: '200px',
          },
        },
        '.location': {
          width: { base: '120px', lg: '225px' },
          overflowWrap: 'anywhere',
        },
        '.category': {
          width: { base: '120px', lg: '175px' },
          fontWeight: 'medium',
          justifyContent: 'flex-end',
        },
      }}
    >
      <Flex className="header">
        <Flex className="cell date">Date</Flex>
        <Flex className="cell emoji" />
        <Flex className="cell title">Title</Flex>
        <Flex className="cell time">Time</Flex>
        <Flex className="cell location">Location</Flex>
        <Flex className="cell category" />
      </Flex>
      {instances.map((instance) => (
        <EntryTableRow
          instance={instance}
          key={instance.id}
          onClick={onEntryClick}
          onToggleHideEntryClick={onToggleHideEntryClick}
        />
      ))}
    </Flex>
  );
};
