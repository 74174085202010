import { type BoxProps, Flex } from '@/ui';

export const FilterButton = (props: BoxProps) => {
  return (
    <Flex
      align="center"
      as="button"
      borderRadius="46px"
      borderWidth="1px"
      fontSize="sm"
      fontWeight="medium"
      px="4"
      py="2"
      transition="all 0.2s ease"
      {...props}
    />
  );
};
