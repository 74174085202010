import type { ReactNode } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { Box, Flex } from '@/ui';

type Props = {
  children: ReactNode;
  isSelected?: boolean;
  onClick?: () => void;
};

export const FeedButton = ({ children, isSelected, onClick }: Props) => {
  return (
    <Flex
      _hover={{ bg: 'gray.100' }}
      align="center"
      borderColor="gray.300"
      borderRadius="3px"
      borderWidth="1px"
      fontSize="sm"
      gap="8"
      p="2"
      role="button"
      transition="all 0.1s ease"
      w="100%"
      onClick={onClick}
    >
      <Box
        flex="1"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {children}
      </Box>
      {isSelected && <AiOutlineCheck />}
    </Flex>
  );
};
