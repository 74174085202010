import { BsTag, BsCalendar3, BsLightningCharge } from 'react-icons/bs';
import { FaRegUser } from 'react-icons/fa';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { getEntryCategoryColors } from '@/pages/Schedule/utils/entry';
import { Flex, Box, type BoxProps, type FlexProps } from '@/ui';
import { setAlpha } from '@/utils/colors';
import { useSnippet } from '../hooks/useSnippet';

const CategoryDot = (props: BoxProps) => (
  <Box borderRadius="50%" h="10px" w="10px" {...props} />
);

export const Formula = (props: FlexProps) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { data: formula, startDate, endDate } = useSnippet();

  if (!formula) {
    return null;
  }

  return (
    <Flex
      borderColor="customgray.gray"
      borderRadius={{ base: '8px', md: '50px' }}
      borderWidth="1px"
      direction={{ base: 'column', md: 'row' }}
      display="inline-flex"
      fontSize="sm"
      fontWeight="medium"
      gap={{ base: 4, md: 8 }}
      lineHeight="1"
      m="auto"
      px="5"
      py="4"
      w={{ base: '100%', md: 'auto' }}
      sx={{
        // reset styles to desktop on print
        '@media print': { flexDir: 'row', w: 'auto', gap: 8 },
      }}
      {...props}
    >
      {formula.categories.length > 0 && (
        <Flex align="center" gap="2">
          {formula.categories.map((category) => {
            const colors = getEntryCategoryColors(category.color);
            const bg = setAlpha(colors.base, 0.3);

            return (
              <Flex align="center" gap={{ base: 2, md: 1 }} key={category.id}>
                <Flex w={{ base: '22px', md: 'auto' }}>
                  <CategoryDot
                    bg={bg}
                    ml={isMobileBreakpoint ? '2px' : 0}
                    mr={category.text ? 2 : 0}
                  />
                </Flex>
                {category.text}
              </Flex>
            );
          })}
        </Flex>
      )}

      {formula.whoLabels.length > 0 && (
        <Flex align="center" gap="2">
          <Flex w={{ base: '22px', md: 'auto' }}>
            <FaRegUser />
          </Flex>
          {formula.whoLabels.map((label) => label.text).join(', ')}
        </Flex>
      )}

      {formula.labels.length > 0 && (
        <Flex align="center" gap="2">
          <Flex w={{ base: '22px', md: 'auto' }}>
            <BsTag />
          </Flex>
          {formula.labels.map((label) => label.text).join(', ')}
        </Flex>
      )}

      {formula.feeds.length > 0 && (
        <Flex align="center" gap="2">
          <Flex w={{ base: '22px', md: 'auto' }}>
            <BsLightningCharge />
          </Flex>
          {formula.feeds.map((feed) => feed.title).join(', ')}
        </Flex>
      )}

      <Flex align="center" gap="2">
        <Flex w={{ base: '22px', md: 'auto' }}>
          <BsCalendar3 />
        </Flex>
        {startDate.toFormat('MMM d, y')} &ndash; {endDate.toFormat('MMM d, y')}
      </Flex>
    </Flex>
  );
};
