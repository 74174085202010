import { Navigate, useLocation } from 'react-router-dom';
import { AppLoader } from '@/components/AppLoader';
import { useCurrentUserContext } from '@/contexts';
import { ScheduleContextProvider } from '@/pages/Schedule/contexts';
import { isGQLErrorOfType } from '@/utils/errors';
import { AuthenticatedScheduleSnippet } from './AuthenticatedScheduleSnippet';
import { AccessDenied } from './components/AccessDenied';
import { SnippetContainer } from './components/SnippetContainer';
import { useSnippet } from './hooks/useSnippet';
import type { SnippetLocationState } from './types';
import { UnauthenticatedScheduleSnippet } from './UnauthenticatedScheduleSnippet';

const Loader = () => {
  const state = useLocation().state as SnippetLocationState;
  const loader = <AppLoader data-testid="snippet-loader" />;

  if (state?.scheduleId) {
    return (
      <SnippetContainer scheduleId={state.scheduleId}>
        {loader}
      </SnippetContainer>
    );
  }

  return loader;
};

export const ScheduleSnippetLoader = () => {
  const { isAuthenticated, isLoading: isLoadingUser } = useCurrentUserContext();
  const {
    data: snippet,
    isLoading: isLoadingSnippet,
    isError,
    error,
  } = useSnippet();

  // See if the page that brought us here told us what the scheduleId is.
  // This is a convienence for logged in users that lets us skip loading the
  // snippet first to retrieve the scheduleId. Unlike other tabs, the snippet
  // loads before the schedule and sometimes the schedule is never loaded at all,
  // like in the case of a public snippet.
  const state = useLocation().state as SnippetLocationState;

  // Note that scheduleId on the snippet will be null if the user isn't a
  // collaborator on the snippet's schedule
  const scheduleId = state?.scheduleId ?? snippet?.scheduleId;

  if (isLoadingSnippet || isLoadingUser) {
    return <Loader />;
  }

  if (isError || !snippet?.id) {
    if (isGQLErrorOfType(error, 'SnippetNotFound')) {
      return <Navigate to={isAuthenticated ? '/' : '/auth'} />;
    }
    if (
      isGQLErrorOfType(error, 'NotLoggedIn') ||
      isGQLErrorOfType(error, 'InvalidPermission')
    ) {
      return (
        <SnippetContainer scheduleId={scheduleId}>
          <AccessDenied />
        </SnippetContainer>
      );
    }
    return (
      <SnippetContainer scheduleId={scheduleId}>
        Error loading snippet
      </SnippetContainer>
    );
  }

  // If we're authenticated and we have a snippet, load the schedule and render
  // the authenticated version of the snippet page.
  if (isAuthenticated && scheduleId) {
    return (
      <ScheduleContextProvider scheduleSlug={scheduleId}>
        {({ isError, isLoading, schedule }) => (
          <SnippetContainer scheduleId={scheduleId}>
            {isLoading ? (
              <AppLoader />
            ) : isError || !schedule ? (
              <>Error loading snippet</>
            ) : (
              <AuthenticatedScheduleSnippet />
            )}
          </SnippetContainer>
        )}
      </ScheduleContextProvider>
    );
  }

  // User isn't authenticated, render a read-only snippet
  return (
    <SnippetContainer>
      <UnauthenticatedScheduleSnippet />
    </SnippetContainer>
  );
};
