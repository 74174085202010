import { CheckIcon } from '@chakra-ui/icons';
import { getEntryCategoryColors } from '@/pages/Schedule/utils/entry';
import { Box } from '@/ui';
import type { DecoratedSnippet } from '../../../types';
import { FilterButton } from './FilterButton';

type Category = DecoratedSnippet['categories'][number];

type Props = {
  category: Category;
  isSelected: boolean;
  onClick: (category: Category) => void;
};

export const CategoryButton = ({ category, isSelected, onClick }: Props) => {
  const colors = getEntryCategoryColors(category.color);

  return (
    <FilterButton
      bg={colors.backgroundColor}
      border="none"
      borderRadius="3"
      fontStyle={category.text ? 'none' : 'italic'}
      key={category.id}
      lineHeight="normal"
      paddingX="3"
      width="100%"
      _hover={{
        bg: colors.hoverBackgroundColor,
      }}
      onClick={() => onClick(category)}
    >
      <Box flex="1" textAlign="left">
        {category.text || <>&nbsp;</>}
      </Box>
      {isSelected && <CheckIcon />}
    </FilterButton>
  );
};
