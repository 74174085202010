import { Flex, Text } from '@/ui';

export const EmptyState = () => {
  return (
    <Flex color="customgray.mid" direction="column" flex="1" textAlign="center">
      <Text fontSize={{ base: '2xl', md: '3xl' }} lineHeight="normal" mt="4">
        This snippet is empty
      </Text>
    </Flex>
  );
};
