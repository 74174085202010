import { FiSend } from 'react-icons/fi';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { Button, type ButtonProps, IconButton } from '@/ui';

export const ShareButton = (props: ButtonProps) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();

  if (isMobileBreakpoint) {
    return (
      <IconButton aria-label="Share snippet" icon={<FiSend />} {...props} />
    );
  }

  return <Button {...props}>Share snippet</Button>;
};
