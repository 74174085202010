import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import {
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@/ui';

type Props = {
  onEdit: () => void;
  onDelete: () => void;
};

export const MoreMenu = ({ onEdit, onDelete }: Props) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();

  return (
    <Menu placement="bottom-end">
      {isMobileBreakpoint ? (
        <MenuButton
          as={IconButton}
          icon={<Icon icon="MoreHorizontal" size="5" />}
          variant="secondary"
        />
      ) : (
        <MenuButton
          as={Button}
          leftIcon={<Icon icon="MoreHorizontal" size="5" />}
          variant="secondary"
        >
          More
        </MenuButton>
      )}
      <MenuList minW="200px">
        <MenuItem icon={<Icon icon="Pencil" />} onClick={onEdit}>
          Edit
        </MenuItem>
        <MenuItem icon={<Icon icon="Trash" />} onClick={onDelete}>
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
